export default [
  `0.75rem`,
  `0.875rem`,
  `1rem`,
  `1.125rem`,
  `1.25rem`,
  `1.5rem`,
  `1.75rem`,
  `2rem`,
  `2.25rem`,
  `2.625rem`,
]
